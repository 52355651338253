import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import MeetManagers from "../components/hire-pages-compoents/HireDevelopers"
import Empower from "../components/hire-pages-compoents/WhyShould"
import Maximize from "../components/hire-pages-compoents/Maximize"
import EasySteps from "../components/hire-saas-developer/EasySteps"
import Greatness from "../components//hire-pages-compoents/Remote"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import Opportunity from "../components/web-application/Opportunity"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Testimonials from "../components/hire-typescript/Testimonials"
import Testimonials2 from "../components/hire-typescript/Testimonials2"
export const Head = () => {
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <title>
        Hire an Experienced Product Manager for Your Team - InvoZone
      </title>
      <meta
        name="description"
        content="Hire a product manager from InvoZone to digitally transform your project, just as 300+ global companies have done. Team of 500+ & guaranteed hiring in 48 Hours!"
      />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
const ProductManager = ({ data }) => {
  //console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhirePm = data?.strapiPage?.sections[1]
  const meet = data?.strapiPage?.sections[2]
  const expertise = data?.strapiPage?.sections[3]
  const capabible = data?.strapiPage.sections[4]
  const steps = data?.strapiPage?.sections[5]
  const great = data?.strapiPage?.sections[6]
  const industry = data?.strapiPage?.sections[7]
  const nextGen = data?.strapiPage?.sections[8]
  const projects = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[11]
  const testimonials = data?.testimonials?.sections[0]

  const devSDetails = [
    {
      name: "Alice Chang",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/alice_chang_cf9a310df6.png",
      experience: "7 years of experience",
      desc: "Skilled in cross-functional collaboration, and master in Agile & Scrum methodologies. Alice has a proven track record for products from conception to successful market launches.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_df89fd802b.svg",
      reviews: "<b>5.0</b> Reviews",
      skills: [
        "Product Management",
        "Prototyping",
        "Agile",
        "Scrum",
        "Jira",
        "IT",
        "Mobile",
        "AI & ML product management",
        "Kanban",
        "B2B product management",
        "Digital",
        "Lean",
        "Product Consultancy",
        "Web",
        "Apps",
        "Product launch",
        "Product ideation",
      ],
    },
    {
      name: "Ali Khan",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ali_khan_985ba9ebb0.png",
      experience: "3 years of experience",
      desc: "Ali with experience in leading cross-functional teams is a pro in Agile methodology and has helped companies in developing user-friendly products within their budget.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.9</b> Stars Reviews",
      skills: [
        "Product Management",
        " Product ideation",
        "Agile",
        "Mobile",
        "Apps",
        "Technical project management",
        "B2B product management",
      ],
    },
    {
      name: "Samina Haider",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/samina_haider_f2e49f16f7.png",
      experience: "2.5 Years Of Experience",
      desc: "Samina contributes to the success of projects through effective communication, adaptability, and a strong commitment to continuous improvement.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "Product Management",
        "Agile Methodology",
        "Product creativity and strategy",
        "Web",
      ],
    },
  ]

  return (
    <MainLayout>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Product Manager"
        productForm={true}
      />
      <Statics productManager={true} />
      <WhyHireAngular strapiData={whyhirePm} />
      <MeetManagers
        strapiData={meet}
        devSDetails={devSDetails}
        hireProduct={true}
      />
      <Empower strapiData={expertise} product={true} Vueidentify={true} />
      <Maximize strapiData={capabible} productmanager={true} />
      <EasySteps strapiData={steps} productSteps={true} />
      <Greatness strapiData={great} />
      <AngularIndustries strapiData={industry} />
      <Opportunity strapiData={nextGen} productOpportunity={true} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={projects} />
      ) : (
        <SolutionsMobile strapiData={projects} />
      )}
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"Our Glowing Testimonials"}
          testiSubtitle={"Read what our clients love about us!"}
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"Our Glowing Testimonials"}
          testiSubtitle={"Read what our clients love about us!"}
        />
      )}

      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "product-manager" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default ProductManager
